@keyframes opacityAnimation {
    0% {
        background-color: rgba(0, 0, 0, 0.4);
    }

    25% {
        background-color: rgba(0, 0, 0, 0.5);
    }

    50% {
        background-color: rgba(0, 0, 0, 0.6);
    }

    75% {
        background-color: rgba(0, 0, 0, 0.7);
    }

    100% {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

.animate-opacityAnimation-start {
    animation: opacityAnimation 0.2s forwards ease;
}

.offcanvansWidth{

    width: 400px !important;

}

.offcanvansWidth2{
    width: 1000px !important;
}


.element {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
}

.element::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}



@keyframes StartingPage {
    0% {
        opacity: 1;
        pointer-events: auto; /* Enable pointer events initially */
    }

    25% {
        opacity: 0.9;
    }

    50% {
        opacity: 0.6;
    }

    75% {
        opacity: 0.3;
    }

    100% {
        opacity: 0;
        pointer-events: none; /* Disable pointer events at the end of animation */
        display: none;
    }
}

.animate-StartingPage {
    animation: StartingPage 0.3s 2s forwards ease;
}



