

.HiddenScroll {
  overflow-y: scroll; /* Adding the ability to scroll */
}

/* Hiding scrollbar for Chrome, Safari and Opera */
.HiddenScroll::-webkit-scrollbar {
    display: none;
}

/* Hiding scrollbar for IE, Edge and Firefox */
.HiddenScroll {
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}