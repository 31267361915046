@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
  color: #848383;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root nav div {
  margin: 0;
  max-width: 100%;
}

#root nav {
  display: flex;
  justify-content: center;
  margin-right: 0px !important;
}

#root .drop button {
  width: 100%;
  overflow: hidden !important;
  outline: none !important;
  background-color: #4c4c4c !important;

}

#root .drop li button {
  background-color: white !important;

}

/* Remove outline on focus */
#root .drop button:focus {
  outline: none;
  box-shadow: none;
  outline: none;
  box-shadow: none;
}

#root .drop button span {
  background-color: #4c4c4c!important;
  width: 100%;
  height: 100% !important;
  border: none !important;
  justify-content: space-between;
}

#root .drop button span:focus {
  outline: none !important;
}


/* #root nav ul {
  background-color: rgba(165, 42, 42, 0.511) !important;
  position: absolute !important;
  height: 800px !important;
  width: 100vw !important;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Changing the text color, in case you need it */
input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}