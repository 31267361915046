/*======================
    404 page
=======================*/

.page_404 {
    /* padding: 50px 0; */
    background: black;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

}

@media screen and (max-width: 480px) {
    
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {

    background-image: url(https://www.goldiran.ir/UserFiles/HTML-404-Error-Page.gif);
    height: 400px;
    background-position: center;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: black !important;
    padding: 10px 20px;

    background: #e6e9e6;
    margin: 20px 0;
    display: none;
}

.contant_box_404 {
    margin-top: -50px;
}