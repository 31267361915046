.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loader is above other content */
}

.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Spinner border color */
  border-top: 4px solid #ffffff; /* Spinner top color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spinner rotation animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  color: #ffffff; /* Loader text color */
  font-size: 16px;
  margin-top: 10px;
}
